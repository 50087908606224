<template>
  <div class="hold-transition">
    <div class="row table-responsive">
      <table
        class="table table-bordered table-striped table-hover table-sm text-center text-xs"
      >
        <thead class="bg-gray text-center">
          <tr class="text-nowrap">
            <th>
              Acciones
              <button
                type="button"
                class="btn btn-sm bg-primary text-white"
                @click="addDetSolicitud()"
                :disabled="
                  estado_det_solicitud == 1 ||
                  !$parent.form.id ||
                  $parent.form.estado == 2 ||
                  $parent.form.estado == 3 ||
                  $parent.form.estado == 4
                "
                style="cursor: pointer"
              >
                <i class="fas fa-plus"></i>
              </button>
            </th>
            <th
              v-if="$parent.form.clase_solicitud == 1 && $parent.userRol"
              style="min-width: 20em"
            >
              Empresa
            </th>
            <th>Tipo Servicio</th>
            <th style="min-width: 20em">Tipo Vehículo / Equipo</th>
            <th style="min-width: 15em">Turno</th>
            <th>Cantidad Vehículos a Solicitar</th>
            <th>Tiempo Requerido del Servicio (Dias)</th>
            <th>Descripción del material o equipos</th>
            <th>Cantidad material</th>
            <th style="min-width: 30em">Largo x Ancho x Alto (MTS)</th>
            <th style="min-width: 10em">Peso(Kg)</th>
            <th>Radio de izaje(Mts)</th>
            <th>Observaciones</th>
          </tr>
        </thead>
        <tbody id="tbody">
          <!-- Editar Registros -->
          <tr v-for="(detSoli, index) in detSolicitudes" :key="detSoli.id">
            <!-- Acciones Botones -->
            <td>
              <div class="btn-group" v-if="estado_det_solicitud != 2">
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  :disabled="
                    estado_det_solicitud != -1 ||
                    $parent.form.estado == 2 ||
                    $parent.form.estado == 3 ||
                    $parent.form.estado == 4
                  "
                  @click="editDetSolicitud(index)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  class="btn btn-sm bg-danger"
                  :disabled="
                    estado_det_solicitud != -1 ||
                    $parent.form.estado == 2 ||
                    $parent.form.estado == 3 ||
                    $parent.form.estado == 4
                  "
                  @click="destroyDetSolicitud(index)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
              <div
                class="btn-group"
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <button
                  type="button"
                  class="btn btn-sm bg-success"
                  v-show="!$v.detSolicitud.$invalid"
                  @click="saveUpdateDetSolicitud(index)"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button
                  class="btn btn-sm bg-danger"
                  @click="cancelDetSolicitud()"
                >
                  <i class="fas fa-ban"></i>
                </button>
              </div>
              <div
                class="btn-group"
                v-if="estado_det_solicitud == 2 && id_actualizar_det !== index"
              >
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  disabled
                  @click="editDetSolicitud(index)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  class="btn btn-sm bg-danger"
                  disabled
                  @click="destroyDetSolicitud(index)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
            <!-- Empresa -->
            <td
              v-if="$parent.form.clase_solicitud == 1 && $parent.userRol"
              class="text-center"
              :class="
                detSoli.empresa_id !== null ? 'table-success' : 'table-warning'
              "
            >
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <v-select
                  :class="[
                    $v.detSolicitud.empresa_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="detSolicitud.empresa"
                  placeholder="Empresa"
                  label="razon_social"
                  class="form-control form-control-sm p-0 mr-3"
                  :options="listasForms.empresas"
                  @input="selectEmpresa()"
                  style="min-width: 100px"
                >
                </v-select>
              </div>
              <div v-else>
                <div
                  v-if="
                    detSoli.empresa !== null && detSoli.empresa !== undefined
                  "
                >
                  {{ detSoli.empresa.razon_social }}
                </div>
                <div v-else></div>
              </div>
            </td>
            <!-- Tipo Servicio -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <select
                  id="tipo_servicio"
                  class="form-control form-control-sm"
                  v-model="detSolicitud.tipo_servicio_obj"
                  :class="
                    $v.detSolicitud.tipo_servicio_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  style="width: 150px"
                  @change="changeTipoServ()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_servicio in listasForms.tipos_servicios"
                    :key="tipo_servicio.numeracion"
                    :value="tipo_servicio"
                  >
                    {{ tipo_servicio.descripcion }}
                  </option>
                </select>
              </div>
              <div v-else>
                <div
                  v-if="
                    detSoli.tipo_servicio_obj !== undefined &&
                    detSoli.tipo_vh_equi_obj !== null
                  "
                >
                  {{ detSoli.tipo_servicio_obj.descripcion }}
                </div>
                <div v-else>
                  {{ detSoli.tipoServicio }}
                </div>
              </div>
            </td>
            <!-- Tipo Vehículo / Equipo -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <div v-if="detSoli.tipo_servicio_obj.numeracion == 1">
                  <select
                    id="tipo_vh_equi"
                    class="form-control form-control-sm"
                    v-model="detSolicitud.tipo_vh_equi_obj"
                    :class="
                      $v.detSolicitud.tipo_vh_equi_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_vehiculo in listasForms.tipos_vehiculos"
                      :key="tipo_vehiculo.id"
                      :value="tipo_vehiculo"
                    >
                      {{ tipo_vehiculo.nombre }}
                    </option>
                  </select>
                </div>
                <div v-if="detSoli.tipo_servicio_obj.numeracion == 2">
                  <select
                    id="tipo_vh_equi"
                    class="form-control form-control-sm"
                    v-model="detSolicitud.tipo_vh_equi_obj"
                    :class="
                      $v.detSolicitud.tipo_vh_equi_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_equipo in listasForms.tipos_equipos"
                      :key="tipo_equipo.numeracion"
                      :value="tipo_equipo"
                    >
                      {{ tipo_equipo.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="
                    detSoli.tipo_vh_equi_obj !== undefined &&
                    detSoli.tipo_vh_equi_obj !== null
                  "
                >
                  <div v-if="detSoli.tipo_servicio_obj.numeracion == 1">
                    {{ detSoli.tipo_vh_equi_obj.nombre }}
                  </div>
                  <div v-else>
                    {{ detSoli.tipo_vh_equi_obj.descripcion }}
                  </div>
                </div>
                <div v-else>
                  <div v-if="detSoli.tipo_servicio == 1">
                    {{ detSoli.tipoVh }} - {{ detSoli.tipoRemolque }}
                  </div>
                  <div v-else>
                    {{ detSoli.tipoEquipo }}
                  </div>
                </div>
              </div>
            </td>
            <!-- Turno  -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <select
                  id="turno"
                  class="form-control form-control-sm"
                  v-model="detSolicitud.turno_obj"
                  :class="
                    $v.detSolicitud.turno_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="turno in listasForms.turnos"
                    :key="turno.id"
                    :value="turno"
                  >
                    {{ turno.descripcion }}
                  </option>
                </select>
              </div>
              <div v-else>
                <div
                  v-if="
                    detSoli.turno_obj !== undefined &&
                    detSoli.turno_obj !== null
                  "
                >
                  {{ detSoli.turno_obj.descripcion }}
                </div>
                <div v-else>
                  {{ detSoli.nTurno }}
                </div>
              </div>
            </td>
            <!-- Cantidad Vehículo -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="cantidad_vehiculos"
                  v-model="detSolicitud.cantidad_vh_equi"
                  :class="
                    $v.detSolicitud.cantidad_vh_equi.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div v-else>
                {{ detSoli.cantidad_vh_equi }}
              </div>
            </td>
            <!-- Tiempo Servicio -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="tiempo_serv"
                  v-model="detSolicitud.tiempo_serv"
                  :class="
                    $v.detSolicitud.tiempo_serv.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div v-else>{{ detSoli.tiempo_serv }}</div>
            </td>
            <!-- Descripción Material -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="descripcion_material"
                  v-model="detSolicitud.descripcion_material"
                  :class="
                    $v.detSolicitud.descripcion_material.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  style="width: 200px"
                />
              </div>
              <div v-else>
                {{ detSoli.descripcion_material }}
              </div>
            </td>
            <!-- Cantidad Material -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="cantidad_material"
                  v-model="detSolicitud.cantidad_material"
                  :class="
                    $v.detSolicitud.cantidad_material.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div v-else>{{ detSoli.cantidad_material }}</div>
            </td>
            <!-- Medidas -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                class="form-group col-md-12 row ml-0 p-0"
              >
                <input
                  type="number"
                  style="font-size: 13px"
                  v-model="detSolicitud.largo"
                  class="form-control form-control-sm col-md-4 pr-0 pl-0 p-1"
                  :class="
                    $v.detSolicitud.largo.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                <input
                  type="number"
                  v-model="detSolicitud.ancho"
                  style="font-size: 13px"
                  class="form-control form-control-sm col-md-4 pr-0 pl-0 p-1"
                  :class="
                    $v.detSolicitud.ancho.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                <input
                  type="number"
                  v-model="detSolicitud.alto"
                  style="font-size: 13px"
                  class="form-control form-control-sm col-md-4 pr-0 pl-0 p-1"
                  :class="
                    $v.detSolicitud.alto.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div v-else>
                {{ detSoli.largo }} X {{ detSoli.ancho }} X {{ detSoli.alto }}
              </div>
            </td>
            <!-- Peso -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="peso"
                  v-model="detSolicitud.peso"
                  :class="
                    $v.detSolicitud.peso.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div v-else>{{ detSoli.peso }}</div>
            </td>
            <!-- Radio Izaje -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <input
                  type="number"
                  v-if="
                    detSolicitud.tipo_servicio_obj &&
                    detSolicitud.tipo_servicio_obj.numeracion == 2
                  "
                  class="form-control form-control-sm"
                  id="radio_izaje"
                  v-model="detSolicitud.radio_izaje"
                  :class="
                    $v.detSolicitud.radio_izaje.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div v-else>{{ detSoli.radio_izaje }}</div>
            </td>
            <!-- Observaciones -->
            <td>
              <div
                v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
              >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="observaciones"
                  v-model="detSolicitud.observaciones"
                  :class="
                    $v.detSolicitud.observaciones.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  style="width: 200px"
                />
              </div>
              <div v-else>{{ detSoli.observaciones }}</div>
            </td>
          </tr>
          <!-- Crear Registro -->
          <tr v-if="estado_det_solicitud == 1">
            <td>
              <div class="btn-group float-right">
                <button
                  class="btn btn-sm btn-success"
                  v-show="!$v.detSolicitud.$invalid"
                  @click="saveDetSolicitud()"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  @click="cancelDetSolicitud()"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
            <!-- Empresa -->
            <td
              v-if="$parent.form.clase_solicitud == 1 && $parent.userRol"
              class="text-center"
            >
              <v-select
                :class="[
                  $v.detSolicitud.empresa_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="detSolicitud.empresa"
                placeholder="Empresa"
                label="razon_social"
                class="form-control form-control-sm p-0 mr-3"
                :options="listasForms.empresas"
                @input="selectEmpresa()"
                style="min-width: 100px"
              >
              </v-select>
              <div
                v-if="
                  (!$v.detSolicitud.empresa_id.$dirty ||
                    $v.detSolicitud.empresa_id.$invalid) &&
                  (detSolicitud.empresa_id == '' ||
                    detSolicitud.empresa_id == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
            <!-- Tipo Servicio -->
            <td>
              <select
                id="tipo_servicio"
                class="form-control form-control-sm"
                v-model="detSolicitud.tipo_servicio_obj"
                v-on:input="$v.detSolicitud.tipo_servicio_obj.$touch"
                :class="
                  $v.detSolicitud.tipo_servicio_obj.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
                style="width: 150px"
                @change="changeTipoServ()"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="tipo_servicio in listasForms.tipos_servicios"
                  :key="tipo_servicio.numeracion"
                  :value="tipo_servicio"
                >
                  {{ tipo_servicio.descripcion }}
                </option>
              </select>
              <div
                v-if="
                  (!$v.detSolicitud.tipo_servicio_obj.$dirty ||
                    $v.detSolicitud.tipo_servicio_obj.$invalid) &&
                  (detSolicitud.tipo_servicio_obj == '' ||
                    detSolicitud.tipo_servicio_obj == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
            <!-- Tipo Vh / Equipo -->
            <td v-if="detSolicitud.tipo_servicio_obj">
              <div v-if="detSolicitud.tipo_servicio_obj.numeracion == 1">
                <select
                  id="tipo_vh_equi"
                  class="form-control form-control-sm"
                  v-model="detSolicitud.tipo_vh_equi_obj"
                  :class="
                    $v.detSolicitud.tipo_vh_equi_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_vehiculo in listasForms.tipos_vehiculos"
                    :key="tipo_vehiculo.id"
                    :value="tipo_vehiculo"
                  >
                    {{ tipo_vehiculo.nombre }}
                  </option>
                </select>
                <div
                  v-if="
                    (!$v.detSolicitud.tipo_vh_equi_obj.$dirty ||
                      $v.detSolicitud.tipo_vh_equi_obj.$invalid) &&
                    (detSolicitud.tipo_vh_equi_obj == '' ||
                      detSolicitud.tipo_vh_equi_obj == null)
                  "
                >
                  <span class="text-danger"
                    ><small>*Campo requerido</small></span
                  >
                </div>
              </div>
              <div v-else>
                <select
                  id="tipo_vh_equi"
                  class="form-control form-control-sm"
                  v-model="detSolicitud.tipo_vh_equi_obj"
                  :class="
                    $v.detSolicitud.tipo_vh_equi_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_equipo in listasForms.tipos_equipos"
                    :key="tipo_equipo.numeracion"
                    :value="tipo_equipo"
                  >
                    {{ tipo_equipo.descripcion }}
                  </option>
                </select>
                <div
                  v-if="
                    (!$v.detSolicitud.tipo_vh_equi_obj.$dirty ||
                      $v.detSolicitud.tipo_vh_equi_obj.$invalid) &&
                    (detSolicitud.tipo_vh_equi_obj == '' ||
                      detSolicitud.tipo_vh_equi_obj == null)
                  "
                >
                  <span class="text-danger"
                    ><small>*Campo requerido</small></span
                  >
                </div>
              </div>
            </td>
            <td v-else>
              <input
                type="text"
                class="form-control form-control-sm p-2"
                disabled
              />
            </td>
            <!-- Turno -->
            <td>
              <select
                id="turno"
                class="form-control form-control-sm"
                v-model="detSolicitud.turno_obj"
                v-on:input="$v.detSolicitud.turno_obj.$touch"
                :class="
                  $v.detSolicitud.turno_obj.$invalid ? 'is-invalid' : 'is-valid'
                "
                style="width: 150px"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="turno in listasForms.turnos"
                  :key="turno.numeracion"
                  :value="turno"
                >
                  {{ turno.descripcion }}
                </option>
              </select>
              <div
                v-if="
                  (!$v.detSolicitud.turno_obj.$dirty ||
                    $v.detSolicitud.turno_obj.$invalid) &&
                  (detSolicitud.turno_obj == '' ||
                    detSolicitud.turno_obj == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
            <!-- Cantidad Vh -->
            <td>
              <input
                type="number"
                class="form-control form-control-sm p-2"
                id="cantidad_vehiculos"
                v-model="detSolicitud.cantidad_vh_equi"
                v-on:input="$v.detSolicitud.cantidad_vh_equi.$touch"
                :class="
                  $v.detSolicitud.cantidad_vh_equi.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
              <div
                v-if="
                  (!$v.detSolicitud.cantidad_vh_equi.$dirty ||
                    $v.detSolicitud.cantidad_vh_equi.$invalid) &&
                  (detSolicitud.cantidad_vh_equi == '' ||
                    detSolicitud.cantidad_vh_equi == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
            <!-- Tiempo Servicio -->
            <td>
              <input
                type="number"
                class="form-control form-control-sm p-2"
                id="tiempo_serv"
                v-model="detSolicitud.tiempo_serv"
                v-on:input="$v.detSolicitud.tiempo_serv.$touch"
                :class="
                  $v.detSolicitud.tiempo_serv.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
              <div
                v-if="
                  (!$v.detSolicitud.tiempo_serv.$dirty ||
                    $v.detSolicitud.tiempo_serv.$invalid) &&
                  (detSolicitud.tiempo_serv == '' ||
                    detSolicitud.tiempo_serv == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
            <!-- Descripción Material -->
            <td>
              <input
                type="text"
                class="form-control form-control-sm p-2"
                id="descripcion_material"
                v-model="detSolicitud.descripcion_material"
                v-on:input="$v.detSolicitud.descripcion_material.$touch"
                :class="
                  $v.detSolicitud.descripcion_material.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
              <div
                v-if="
                  (!$v.detSolicitud.descripcion_material.$dirty ||
                    $v.detSolicitud.descripcion_material.$invalid) &&
                  (detSolicitud.descripcion_material == '' ||
                    detSolicitud.descripcion_material == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
            <!-- Cantidad Material -->
            <td>
              <input
                type="number"
                class="form-control form-control-sm p-2"
                id="cantidad_material"
                v-model="detSolicitud.cantidad_material"
                v-on:input="$v.detSolicitud.cantidad_material.$touch"
                :class="
                  $v.detSolicitud.cantidad_material.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
              <div
                v-if="
                  (!$v.detSolicitud.cantidad_material.$dirty ||
                    $v.detSolicitud.cantidad_material.$invalid) &&
                  (detSolicitud.cantidad_material == '' ||
                    detSolicitud.cantidad_material == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
            <!-- Medidas -->
            <td>
              <div class="form-group col-md-12 row ml-1 p-0">
                <input
                  type="number"
                  style="font-size: 13px"
                  v-model="detSolicitud.largo"
                  class="form-control form-control-sm col-md-4 pr-0 pl-1"
                  :class="
                    $v.detSolicitud.largo.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                <input
                  type="number"
                  v-model="detSolicitud.ancho"
                  style="font-size: 13px"
                  class="form-control form-control-sm col-md-4 pr-0 pl-1"
                  :class="
                    $v.detSolicitud.ancho.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                <input
                  type="number"
                  v-model="detSolicitud.alto"
                  style="font-size: 13px"
                  class="form-control form-control-sm col-md-4 pr-0 pl-1"
                  :class="
                    $v.detSolicitud.alto.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </td>
            <!-- Peso -->
            <td>
              <input
                type="number"
                class="form-control form-control-sm p-2"
                id="peso"
                v-model="detSolicitud.peso"
                :class="
                  $v.detSolicitud.peso.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
              <div
                v-if="
                  (!$v.detSolicitud.peso.$dirty ||
                    $v.detSolicitud.peso.$invalid) &&
                  (detSolicitud.peso == '' || detSolicitud.peso == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
            <!-- Radio Izaje -->
            <td>
              <input
                type="number"
                v-if="
                  detSolicitud.tipo_servicio_obj &&
                  detSolicitud.tipo_servicio_obj.numeracion == 2
                "
                class="form-control form-control-sm p-2"
                id="radio_izaje"
                v-model="detSolicitud.radio_izaje"
                :class="
                  $v.detSolicitud.radio_izaje.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
              <div
                v-if="
                  detSolicitud.tipo_servicio_obj &&
                  detSolicitud.tipo_servicio_obj.numeracion == 2 &&
                  (!$v.detSolicitud.radio_izaje.$dirty ||
                    $v.detSolicitud.radio_izaje.$invalid) &&
                  (detSolicitud.radio_izaje == '' ||
                    detSolicitud.radio_izaje == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
            <!-- Observaciones -->
            <td>
              <input
                type="text"
                class="form-control form-control-sm"
                id="observaciones"
                v-model="detSolicitud.observaciones"
                :class="
                  $v.detSolicitud.observaciones.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
                style="width: 200px"
              />
              <div
                v-if="
                  (!$v.detSolicitud.observaciones.$dirty ||
                    $v.detSolicitud.observaciones.$invalid) &&
                  (detSolicitud.observaciones == '' ||
                    detSolicitud.observaciones == null)
                "
              >
                <span class="text-danger"><small>*Campo requerido</small></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import axios from "axios";

export default {
  name: "CsSolicitudesInternasDetSolicitud",
  components: {
    vSelect,
  },
  data() {
    return {
      detSolicitudes: [],
      detSolicitud: {
        id: null,
        empresa_id: null,
        cs_solicitud_interna_id: null,
        tipo_servicio_obj: null,
        tipo_servicio: null,
        tipo_vh_equi_obj: null,
        turno_obj: null,
        turno: null,
        tipo_vh_equi: null,
        tipo_remolque: null,
        cantidad_vh_equi: null,
        tiempo_serv: null,
        descripcion_material: null,
        cantidad_material: null,
        largo: null,
        ancho: null,
        alto: null,
        peso: null,
        radio_izaje: null,
        observaciones: null,
        estado: null,
      },
      listasForms: {
        tipos_servicios: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
        turnos: [],
        empresas: [],
      },
      estado_det_solicitud: -1,
      id_actualizar_det: -1,
      detSolicitudAnt: null,
    };
  },
  validations() {
    let empresa_id = {};
    let radio_izaje = {};
    if (
      this.detSolicitud.tipo_servicio_obj &&
      this.detSolicitud.tipo_servicio_obj.numeracion == 2
    ) {
      radio_izaje = {
        required,
      };
    }
    if (
      this.detSolicitud.tipo_servicio_obj &&
      this.detSolicitud.tipo_servicio_obj.numeracion == 1
    ) {
      radio_izaje = {
        required: false,
      };
    }
    if (this.$parent.form.clase_solicitud == 1 && this.$parent.userRol) {
      empresa_id = {
        required,
      };
    }
    if (
      (this.$parent.form.clase_solicitud == 1 && !this.$parent.userRol) ||
      this.$parent.form.clase_solicitud == 2
    ) {
      empresa_id = {
        required: false,
      };
    }

    let detSolicitud = {
      tipo_servicio_obj: {
        required,
      },
      tipo_vh_equi_obj: {
        required,
      },
      turno_obj: {
        required,
      },
      empresa_id: empresa_id,
      cantidad_vh_equi: {
        required,
      },
      tiempo_serv: {
        required,
      },
      descripcion_material: {
        required,
      },
      cantidad_material: {
        required,
      },
      largo: {
        required,
      },
      ancho: {
        required,
      },
      alto: {
        required,
      },
      peso: {
        required,
      },
      radio_izaje: radio_izaje,
      observaciones: {
        required,
      },
    };

    return {
      detSolicitud,
    };
  },

  methods: {
    async getDetSolicitudes() {
      await axios
        .get("/api/funcionariosFrontera/detSolicitudesInternas", {
          params: { solicitudInterna_id: this.$parent.form.id },
        })
        .then(async (response) => {
          this.detSolicitudes = response.data;
          await this.llenarDetSolicitud();
        });
    },

    async getTipoServicio() {
      await axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    async getTipoVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          let lista = [];
          for (let i = 0; i < respuesta.length; i++) {
            lista.push({
              id: respuesta[i].id,
              tipo: "tv",
              tipo_vehi: respuesta[i].id,
              nombre: respuesta[i].nombre,
            });
          }

          axios
            .get("/api/lista/187", {
              params: {
                cod_alternos: [1, 3],
              },
            })
            .then(function (response) {
              let respuestaR = response.data;
              for (let i = 0; i < respuestaR.length; i++) {
                lista.push({
                  id: respuestaR[i].numeracion,
                  tipo: "tr",
                  tipo_vehi: 1,
                  nombre: "CABEZOTE - " + respuestaR[i].descripcion,
                });
              }
            })
            .catch(function (error) {});

          me.listasForms.tipos_vehiculos = lista;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTipoEquipo() {
      let me = this;
      await axios
        .get("/api/lista/129", {
          params: {
            cod_alternos: [1, 3],
          },
        })
        .then(function (response) {
          me.listasForms.tipos_equipos = response.data;
        })
        .catch(function (error) {});
    },

    async getTipoComplementarios() {
      await axios.get("/api/lista/195").then((response) => {
        this.listasForms.tipos_complementos = response.data;
      });
    },

    async getTurnos() {
      await axios.get("/api/lista/188").then((response) => {
        this.listasForms.turnos = response.data;
      });
    },

    changeTipoServ() {
      this.detSolicitud.tipo_vh_equi_obj = null;
      this.detSolicitud.radio_izaje = null;
    },

    soloNumeros(evt) {
      let keynum = null;
      const maskPattern = /^(\d{3}X){2}\d{3}$/; // Expresión regular para la máscara "###X###X###"
      const inputValue = evt.target.value;

      if (window.event) {
        keynum = evt.keyCode; //IE
      } else {
        keynum = evt.which; //FF
      }

      if (
        (keynum > 47 && keynum < 58) ||
        keynum == 6 ||
        keynum == 189 ||
        keynum == 110 ||
        maskPattern.test(inputValue)
      ) {
        this.estado_medidas = true;
      } else {
        this.estado_medidas = null;
      }
    },

    async llenarDetSolicitud() {
      this.detSolicitudes.forEach(async (element) => {
        element.tipo_servicio_obj = this.listasForms.tipos_servicios.filter(
          (item) => {
            if (item.numeracion == element.tipo_servicio) {
              return item;
            }
          }
        )[0];

        element.turno_obj = this.listasForms.turnos.filter((item) => {
          if (item.numeracion == element.turno) {
            return item;
          }
        })[0];

        /* if (element.tipo_servicio == 1) {
          element.tipo_vh_equi_obj = this.listasForms.tipos_vehiculos.filter(
            (item) => {
              if (
                item.tipo_vehi == element.tipo_vh_equi &&
                element.tipo_remolque == item.id
              ) {
                let tv = {};
                if (element.tipo_remolque !== null) {
                  tv = {
                    id: item.numeracion,
                    tipo: "tr",
                    tipo_vehi: 1,
                    nombre: "CABEZOTE - " + item.descripcion,
                  };
                } else {
                  tv = {
                    id: item.id,
                    tipo: "tv",
                    tipo_vehi: item.id,
                    nombre: item.nombre,
                  };
                }

                return tv;
              }
            }
          )[0];
        }

        if (element.tipo_servicio == 2) {
          element.tipo_vh_equi_obj = this.listasForms.tipos_equipos.filter(
            async (item) => {
              if (item.numeracion == element.tipo_vh_equi) {
                return item;
              }
            }
          )[0];
        } */
      });
    },

    async addDetSolicitud() {
      await this.limpiarDetSolicitud();
      this.detSolicitud.cs_solicitud_interna_id = this.$parent.form.id;
      this.estado_det_solicitud = 1;
    },

    async cancelDetSolicitud() {
      this.estado_det_solicitud = -1;
    },

    async saveDetSolicitud() {
      let me = this;
      if (me.detSolicitud.tipo_servicio_obj.numeracion == 1) {
        if (me.detSolicitud.tipo_vh_equi_obj.tipo == "tv") {
          me.detSolicitud.tipo_vh_equi = me.detSolicitud.tipo_vh_equi_obj.id;
          me.detSolicitud.tipo_remolque = null;
        } else if (me.detSolicitud.tipo_vh_equi_obj.tipo == "tr") {
          me.detSolicitud.tipo_vh_equi = 1;
          me.detSolicitud.tipo_remolque = me.detSolicitud.tipo_vh_equi_obj.id;
        }
      } else {
        me.detSolicitud.tipo_vh_equi =
          me.detSolicitud.tipo_vh_equi_obj.numeracion;
        me.detSolicitud.tipo_remolque = null;
      }

      let estado = null;
      if (this.$parent.form.presupuesto_verificado == 1) {
        estado = 1;
      } else {
        estado = 3;
      }
      me.detSolicitud.estado = estado;
      me.detSolicitud.tipo_servicio =
        me.detSolicitud.tipo_servicio_obj.numeracion;
      me.detSolicitud.turno = me.detSolicitud.turno_obj.numeracion;
      me.detSolicitudes.push(me.detSolicitud);
      me.estado_det_solicitud = -1;
    },

    async saveDetSolicitudTotal() {
      axios({
        method: "POST",
        url: "/api/funcionariosFrontera/detSolicitudesInternas",
        data: {
          cs_solicitud_interna_id: this.$parent.form.id,
          detSolicitudes: this.detSolicitudes,
          presupuesto_verificado: this.$parent.form.presupuesto_verificado,
        },
      })
        .then(async () => {
          await this.getDetSolicitudes();
          this.guardar++;
        })
        .catch((e) => {
          this.message += e.message;
        });
    },

    async editDetSolicitud(index) {
      this.id_actualizar_det = index;
      this.estado_det_solicitud = 2;

      if (this.detSolicitudes[index].tipo_servicio == 1) {
        if (this.detSolicitudes[index].tipo_remolque !== null) {
          this.detSolicitudes[index].tipo_vh_equi_obj =
            this.listasForms.tipos_vehiculos.find(
              (item) =>
                item.tipo_vehi == this.detSolicitudes[index].tipo_vh_equi &&
                item.id == this.detSolicitudes[index].tipo_remolque &&
                item.tipo == "tr"
            );
        } else {
          this.detSolicitudes[index].tipo_vh_equi_obj =
            this.listasForms.tipos_vehiculos.find(
              (item) =>
                item.tipo_vehi == this.detSolicitudes[index].tipo_vh_equi &&
                item.tipo == "tv"
            );
        }
      }

      if (this.detSolicitudes[index].tipo_servicio == 2) {
        this.detSolicitudes[index].tipo_vh_equi_obj =
          this.listasForms.tipos_equipos.find(
            (item) => item.numeracion == this.detSolicitudes[index].tipo_vh_equi
          );
      }

      this.detSolicitud = { ...this.detSolicitudes[index] };
    },

    async saveUpdateDetSolicitud(index) {
      if (this.detSolicitud.tipo_servicio_obj.numeracion == 1) {
        this.detSolicitud.tipo_vh_equi = this.detSolicitud.tipo_vh_equi_obj.id;
      } else {
        this.detSolicitud.tipo_vh_equi =
          this.detSolicitud.tipo_vh_equi_obj.numeracion;
      }

      this.detSolicitud.tipo_servicio =
        this.detSolicitud.tipo_servicio_obj.numeracion;
      this.detSolicitud.turno = this.detSolicitud.turno_obj.numeracion;

      let estado = null;
      if (this.$parent.form.presupuesto_verificado == 1) {
        estado = 1;
      } else {
        estado = 3;
      }

      this.detSolicitud.estado = estado;
      this.detSolicitudes[index] = this.detSolicitud;
      this.estado_det_solicitud = -1;
      this.id_actualizar_det = -1;
    },

    async destroyDetSolicitud(index) {
      this.detSolicitudes.splice(index, 1);
    },

    async limpiarDetSolicitud() {
      this.detSolicitud = {
        id: null,
        cs_solicitud_interna_id: null,
        tipo_servicio: null,
        tipo_servicio_obj: null,
        tipo_vh_equi: null,
        tipo_vh_equi_obj: null,
        cantidad_vh_equi: null,
        tiempo_serv: null,
        descripcion_material: null,
        cantidad_material: null,
        largo: null,
        ancho: null,
        alto: null,
        peso: null,
        turno: null,
        radio_izaje: null,
        observaciones: null,
        empresa: null,
        empresa_id: null,
      };
    },

    async getEmpresas() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then(function (response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function (e) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectEmpresa() {
      this.detSolicitud.empresa_id = null;
      if (
        this.detSolicitud.empresa !== null &&
        this.detSolicitud.empresa !== undefined
      ) {
        this.detSolicitud.empresa_id = this.detSolicitud.empresa.id;
      }
    },
  },

  async mounted() {
    await this.getTipoVehiculos();
    await this.getTipoEquipo();
    await this.getTurnos();
    await this.getTipoServicio();
    await this.getEmpresas();
  },
};
</script>
